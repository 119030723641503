import React from 'react';
import {Container, Row} from 'react-bootstrap';
// import { Fade } from 'react-reveal';
import Project from './Project';
import regeneroot from '../images/regeneroot.jpg'
import art_marketplace from '../images/art_marketplace.png'
import qr_portal from '../images/qr_portal.png'
import resto from '../images/resto.png'
import scheduler from '../images/scheduler.png'


const Projects = () => {

  return (
    <section className="projects">
      <Container className="projects">
        <h2 className='comp-title2'>
          Project Samples
        </h2>
        <Row>
            <Project 
            name='- QuintRooms Portal Site -'
            image={qr_portal}
            demo={true}
            demoPath='https://events.quintrooms.com/group-event?id=50760'
            text='An example of a QuintRooms portal, which delivers exclusive inventory for client events.'
            />
        </Row>
        <Row>
            <Project 
            name='- Art Marketplace (In Progress) -'
            image={art_marketplace}
            github={true}
            githubPath='https://github.com/derekb123/regeneroot3'
            text='A full stack web app built with React, Node, PostgreSQL, AWS RDS, and AWS S3. The project has a secure JWT web token architecture, and is an exercise in state management. There is a goal to eventually integrate Solidity and the Etherum network so that actual NFTs can be bought and sold. (I shut down my AWS integration to avoid charges :( )'
            />
        </Row>
        <Row>
            <Project 
            name='- Regeneroot -'
            image={regeneroot}
            demo={true}
            demoPath='https://regeneroot3.netlify.app/'
            github={true}
            githubPath='https://github.com/derekb123/art_marketplace'
            text='A full-stack web app that helps urban farmers connect with land owners who would like to lease their land (essentially an Airbnb for urban farmers!). Gardeners can log in, search lots in various ways, message owners, and checkout a lease agreement. Owners can login, create, edit, delete and manage their properties. This was a group project (with 2 other team members) built in less than 2 weeks.'
            />
        </Row>
        <Row>
          {/* <Fade top={true} duration={1200} delay={400} distance='50px'> */}
            <Project
            name='- Rest-O -'
            image={resto}
            github={true}
            githubPath='https://github.com/derekb123/LHL-Midterm'
            text='RestO! is your friendly food ordering web application, which allows users to place orders and receive SMS notifications about their order status. It was a group project (with 2 other team members) built in one week using Node, Express, PSQL, Twilio, JavaScript, jQuery, HTML and SASS. My main contributions were to the backend routing, database interaction, and the frontend requests for the cart functions.'
            />
          {/* </Fade> */}
        </Row>
        <Row>
          {/* <Fade top={true} duration={1200} delay={400} distance='50px'> */}
            <Project
            name='- Scheduler -'
            image={scheduler}
            github={true}
            githubPath='https://github.com/derekb123/scheduler'
            text='An app to facilitate a student scheduling an interview appointment with an interviewer on a specific time and day of the week. I worked to implement the React components and the testing.'
            />
          {/* </Fade> */}
        </Row>
      </Container>
    </section>
  )
}

export default Projects;